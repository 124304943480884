import axios from 'axios'

const loadLangScriptsInParallel = ({ langScriptsKeys, appName, microfrontendScripts, manifest }) => {
  const loadScript = (scriptKey) => {
    return new Promise((resolve, reject) => {
      const langScript = document.createElement('script');
      langScript.type = 'text/javascript';
      langScript.src = manifest[scriptKey];
      langScript.id = `${appName}-${scriptKey}`;
      langScript.onload = resolve;
      langScript.onerror = reject;
      document.head.appendChild(langScript);

      microfrontendScripts.push(langScript.id)
    });
  }

  return Promise.all(langScriptsKeys.map(loadScript));
}

const handleMicroFrontendLoad = async ({
  appName,
  history,
  globalFunction,
  manifestData,
  language,
  country,
  environmentConfig,
  setIsLoading
}) => {
  const manifest = manifestData.data
  const microfrontendScripts = []

  const loadApplication = () => {
    if (manifest['app.js']) {
      const mfeLang = `${language.toLowerCase()}_${country.toUpperCase()}`
      const script = document.createElement('script')

      script.type = 'text/javascript'
      script.src = manifest['app.js']
      script.id = `${appName}-application`

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      script.onload = () => window[globalFunction](appName, history, environmentConfig)

      microfrontendScripts.push(script.id)

      document.body.appendChild(script)

      setIsLoading(false)

      document.getElementById(appName).setAttribute('lang', mfeLang)
    }

    if (manifest['app.css']) {
      const style = document.createElement('link')
      style.rel = 'stylesheet'
      style.href = manifest['app.css']
      style.id = `${appName}-styles`

      microfrontendScripts.push(style.id)

      document.head.appendChild(style)
    }
  }

  const loadVendors = () => {
    const script = document.createElement('script')
    script.type = 'text/javascript'
    script.src = manifest['vendors.js']
    script.id = `${appName}-vendors`
    script.onload = loadApplication

    microfrontendScripts.push(script.id)

    document.body.appendChild(script)
  }

  const handleLoad = manifest['vendors.js'] ? loadVendors : loadApplication
  let langScripts = Object.keys(manifest).filter((key) =>
    key.toLowerCase().includes(`${language}_${country}`.toLowerCase())
  )

  if (langScripts.length === 0) {
    const localeToInclude = language === 'nb' ? 'no_no' : 'en_us'
    langScripts = Object.keys(manifest).filter((key) =>
      key.toLowerCase().includes(localeToInclude)
    )
  }

  if (langScripts.length > 0) {
    const langScriptsParams = {
      appName,
      langScriptsKeys: langScripts,
      manifest,
      microfrontendScripts,
    }

    await Promise.all([loadLangScriptsInParallel(langScriptsParams)]);
  } 
  handleLoad();
  

  return microfrontendScripts
}

export const loadManifest = async (mfeArguments) => {
  const { setIsLoading, manifest } = mfeArguments

  setIsLoading(true)

  const manifestData = await axios.get(manifest)

  return handleMicroFrontendLoad({ ...mfeArguments, manifestData })
}

export const unloadManifest = (microFrontendScripts) => {
  microFrontendScripts.forEach((script) => {
    const elem = document.getElementById(script)
    elem.parentNode.removeChild(elem)
  })
}
