export function getPortalLanguage() {
    if (window.Shell) {
        return window.Shell.v1.localization.language;
    }

    return 'en';
}

export function getPortalLocale() {
    return window.Shell?.v1?.localization?.locale || 'en-US';
}
