import { authHeader } from './authHeader';
import {getGeminiAbsolutePath} from "./routingHelpers";
import axios from 'axios';

export async function getTenantLocale(stack, acceptLanguage) {
    const header = await authHeader();

    const headers = {
        ...header,
        'Accept-Language': acceptLanguage
    };

    try {
        const locale = await axios.get(getGeminiAbsolutePath(stack, '/api/dashboard/v1/user/locale'), { headers });

        if (locale && locale.data && locale.data.locale) {
            return locale.data.locale;
        }

        return null;
    } catch(e) {
        if (e.response && e.response.status === 404){
            return { status: 404 };
        }
        return null;
    }
}
