export function getGeminiPath(path = '') {
    let strippedPath = path.split('/').filter(x => x).join('/');
    return `${(window._capybaraTestHost || "")}/${strippedPath}`;
}

export function getGeminiAbsolutePath(stack, path = "") {
    switch (stack) {
        case "dev":
            return "https://gemini-pie.hpconnectedpie.com".concat(path);
        case "pie1":
            return "https://instantink-pie1.hpconnectedpie.com".concat(path);
        case "stage1":
            return "https://instantink-stage1.hpconnectedstage.com".concat(path);
        case "production":
            return "https://instantink.hpconnected.com".concat(path);
        default:
            return path;
    }
}
