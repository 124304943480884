import React, { useEffect, useState } from 'react'
import { loadManifest, unloadManifest } from './loadManifest'
import { getTenantLocale } from '../../helpers/tenantLocale'
import { getManifestUrl } from '../../helpers/manifestUrl'
import { getStack } from '../../helpers/stackHelpers'
import { getPortalLanguage } from '../../helpers/languageHelper'
import { getPortalLocale } from '../../helpers/languageHelper'
import { checkIsConsumerPortal } from '../../helpers/enviormentHelper'
import ProgressIndicator from '@veneer/core/dist/scripts/progress_indicator'
import { getJWebPlatform, getJWebIsNative } from "../../helpers/jwebHelper";

import { Container, Spinner } from './styles'

const VulcanLoader = ({
  appName = 'instantink-vulcan',
  history = null,
  environmentConfig = {
    isJWebAndroidApp: false,
    isJWebDesktopApp: false,
    isJWebiOSApp: false,
    isSmb: true
  },
  ...props
}) => {
  const [isLoading, setIsLoading] = useState(false)
  environmentConfig.isSmb = !checkIsConsumerPortal(props.properties);
  if (getJWebIsNative()) {
    environmentConfig.isJWebAndroidApp = getJWebPlatform() === 'android'
    environmentConfig.isJWebiOSApp = getJWebPlatform() === 'ios'
    environmentConfig.isJWebDesktopApp = getJWebPlatform() === 'windows' || getJWebPlatform() === 'mac'
  }

  useEffect(() => {
    let microFrontendScripts = []
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    async function loadMicroFrontend() {
      setIsLoading(true)

      let globalFunction = "renderVulcanShell"

      const stack = getStack(props.stack);

      const manifest = getManifestUrl(stack)

      let country = 'us'
      let language = 'en'

      const acceptLanguage = getPortalLanguage();

      const tenantLocale = await getTenantLocale(stack, acceptLanguage)

      if (tenantLocale) {
        if (tenantLocale.status && tenantLocale.status === 404) {
          globalFunction = "renderPromotionalPage"
          const portalLocaleFallback = getPortalLocale()
          const localeOfReference = navigator.language.split('-').length === 2
              ? navigator.language
              : portalLocaleFallback

          language = localeOfReference.split('-')[0]
          country = localeOfReference.split('-')[1].toLowerCase()
        } else {
          language = tenantLocale.split('_')[0]
          country = tenantLocale.split('_')[1].toLowerCase()
        }
      }

      const mfeArguments = {
        appName,
        globalFunction,
        history,
        manifest,
        language,
        country,
        environmentConfig,
        setIsLoading
      }

      microFrontendScripts = await loadManifest(mfeArguments)

    }

    loadMicroFrontend()

    return () => {
      unloadManifest(microFrontendScripts)
    }

  }, [])

  return (
    <Container>
      {isLoading ? (
        <Spinner>
          <ProgressIndicator />
        </Spinner>
      ) : (
        <main id={appName} className={appName} />
      )}
    </Container>
  )
}

export default VulcanLoader
