export async function authHeader() {
    // return authorization header with stratus jwt token
    if (window.Shell) {
        const currentStratusToken = await window.Shell.v1.authProvider.getAccessToken();

        if (currentStratusToken) {
            return { Authorization: `Bearer ${currentStratusToken}` };
        } else {
            return {};
        }
    }

    return {};
}
