import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const Spinner = styled.div`
    height: calc(100vh - 190px);
    display: flex;
    align-items: center;
    justify-content: center;
`
