export const getManifestUrl = (stack) => {
    switch(stack) {
        case 'pie1':
            return 'https://assets-pie1.instantink.com/vulcan/manifest.json';
        case 'stage1':
            return 'https://assets-stage1.instantink.com/vulcan/manifest.json';
        case 'production':
            return 'https://assets.instantink.com/vulcan/manifest.json';
    }
}
